<template>
    <div class="page-content">
        <page-breadcrumb title="Speaking Test Level" class="mb-2" />
        
		<div class="d-flex justify-content-start align-items-center">
			<data-create-popup title="Create Group"
				:schema="create_schema" :default-data="defaultObject"
				:create-data-fn="createItem" @create="$refs.group_list.getList()"
			/>
		</div>
      <data-table-ssr id="group_list" ref="group_list"
          :columns="fields" :get-list-fn="getList"
          :to-edit-fn="toEditPage"
      >
        <template #slot_level="{slotData}">
          Level {{ slotData.level }}
        </template> 
      </data-table-ssr>
    </div>
</template>
<script>
const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Level Text', field: 'level_text', input_type: 'ui-component', validate: { required: true }},
    ]
  }
];

const fields = [
  { label: 'Level', field: 'level', input_type: 'slot'},
  { label: 'Level Text', field: 'level_text', display_type: 'translatable-text' },
];
import defaultObject from '../defaultObject.js'
import service from '../service'
export default {
    data(){
    return{
      create_schema,
      fields,
      total: 0,
      defaultObject: JSON.parse(JSON.stringify(defaultObject)),
    }
  },
  watch: {
    filter: {
      handler() {
        this.$refs.group_list.getList();
      },
      deep: true,
    },
  },
  methods:{
    async createItem(data) {
      let new_item = await service.create({ ...data, level: this.total });
      return new_item;
    },
    async getList({ limit, page }) {
      let response_data = await service.getList({
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    toEditPage({ _id }) {
      this.$router.push({ name: 'native11-speaking-test-detail', params: { id: _id } });
    },
  }
}
</script>